import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "@/components/biz/pages/users/ais/store";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import rehypeRaw from "rehype-raw";
import rehypeSanitize, {defaultSchema} from "rehype-sanitize";
import {toast} from "react-toastify";

const downloadFile = (filename: string, content: string) => {
  const blob = new Blob([content], {type: "text/plain"});
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const SpeakMessages: React.FC = () => {
  const ai = useSelector((state: RootState) => state.chat.ai);
  const speakMessages = useSelector((state: RootState) => state.chat.speakMessages);

  const customSchema = {
    ...defaultSchema,
    tagNames: [...defaultSchema.tagNames, "ins", "del", "sup", "sub", "mark", "kbd"],
  };

  const handleDownload = (message: string) => {
    const filename = `${ai.slug}-${Date.now()}.txt`;
    downloadFile(filename, message);
    toast.success("ダウンロードしました");
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("コピーしました");
    });
  };

  return (
    <div className="grow">
      {Object.entries(speakMessages).map(([token, messageGroup]) => {
        const messageContent = messageGroup.responses.join("");
        return (
          <div key={token} className="grid grid-cols-1 gap-y-3 mb-4 grow">
            <div className="bg-blue-50 border border-gray-300 p-4 rounded-lg">
              <p dangerouslySetInnerHTML={{__html: messageGroup.input}}></p>
            </div>
            <div className="bg-white border border-gray-300 p-4 rounded-lg">
              <div className="flex justify-end space-x-2 py-2">
                <ActionButton
                  label="ダウンロード"
                  tooltip="クリックでダウンロード"
                  onClick={() => handleDownload(messageContent)}
                />
                <ActionButton
                  label="コピー"
                  tooltip="クリックでコピーできます"
                  onClick={() => handleCopy(messageContent)}
                />
              </div>
              <div className="prose">
                <ReactMarkdown
                  remarkPlugins={[remarkGfm, remarkBreaks]}
                  rehypePlugins={[rehypeRaw, [rehypeSanitize, customSchema]]}
                >
                  {messageContent}
                </ReactMarkdown>
                {messageGroup.references && messageGroup.references.length > 0 && (
                  <References references={messageGroup.references}/>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const ActionButton: React.FC<{ label: string; tooltip: string; onClick: () => void }> = ({
                                                                                           label,
                                                                                           tooltip,
                                                                                           onClick,
                                                                                         }) => (
  <div className="relative group">
    <span className="balloon">{tooltip}</span>
    <button
      type="button"
      className="px-3 py-2 border border-gray-300 rounded hover:opacity-90"
      onClick={onClick}
    >
      <span className="text-md font-semibold">{label}</span>
    </button>
  </div>
);

const References: React.FC<{ references: { downloadUrl: string; filename: string }[] }> = ({references}) => (
  <div>
    【参照元ファイル】
    <br/>
    {references.map((reference, index) => (
      <a
        key={index}
        href={reference.downloadUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="underline text-blue-700 hover:text-blue-500"
      >
        {reference.filename}
      </a>
    ))}
  </div>
);

export default SpeakMessages;
