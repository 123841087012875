import getClient from "../../client";
import { AxiosResponse } from "axios";
import {Thread} from "@/interfaces/biz/users_ai";

export const usersTextGenerateDetail = async (
  slug: string,
  id: string
): Promise<AxiosResponse<Thread>> => {
  return getClient().get(`/biz/users/ais/${slug}/threads/${id}.json`);
};

export type UploadFileResponse = {
  status: string;
  inputName: string;
  fileIdentifier: string;
};

/**
 * AIメニューの入力ファイルをアップロードする
 *
 * @param {string} slug
 * @param {string} inputName
 * @param {File} file
 * @param {string} formToken
 */
export const uploadFile = async (
  slug: string,
  inputName: string,
  file: File,
  formToken: string
): Promise<AxiosResponse<UploadFileResponse>> => {
  const formData = new FormData();
  formData.append('input_name', inputName);
  formData.append('file', file);
  formData.append('form_token', formToken);
  formData.append('sample_data', formToken);

  return getClient().post(`/biz/users/ais/${slug}/upload_file.json`, formData);
}
