import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

type Props = {};
import {
  Book,
  ChevronRightCircle,
  UserPlus,
  LogIn,
  Image,
  PenLine,
  FileText,
} from "lucide-react";

import Cookies from "js-cookie";
const googtransValue = Cookies.get("googtrans");

const SidebarLists: React.FC<Props> = (props) => {
  const [openAISection, setOpenAISection] = useState(() => {
    const storedState = localStorage.getItem("openAISection");
    return storedState ? JSON.parse(storedState) : false;
  });

  useEffect(() => {
    localStorage.setItem("openAISection", JSON.stringify(openAISection));
  }, [openAISection]);

  const toggleOpenAISection = (e) => {
    e.preventDefault();
    setOpenAISection(!openAISection);
  };

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <>
      <ul className="space-y-1">
        <ul role="list" className="flex flex-1 flex-col gap-y-7">
          <li>
            <ul role="list" className="space-y-1">
              {!googtransValue && (
                <li>
                  <a
                    className="flex items-center p-2 text-md font-semibold text-gray-700 rounded-lg hover:bg-gray-100 "
                    href="/ais"
                  >
                    <div className="text-gray-700">
                      <PenLine className="w-5 h-5" />
                    </div>
                    <span className="ml-2">テキスト生成ツール</span>
                  </a>
                </li>
              )}
              <li>
                <a
                  className="flex items-center p-2 text-md font-semibold text-gray-700 rounded-lg hover:bg-gray-100 "
                  href="/image_ais"
                >
                  <div className="text-gray-700">
                    <Image className="w-5 h-5" />
                  </div>
                  <span className="ml-2">画像生成ツール</span>
                </a>
              </li>
              <li>
                <a
                  href={`#`}
                  className={classNames(
                    "group flex rounded-md px-2 py-2 text-md leading-6 font-semibold text-gray-700 items-center hover:bg-gray-100 relative"
                  )}
                  onClick={toggleOpenAISection}
                >
                  <div className="text-gray-700">
                    <Book className="w-5 h-5" />
                  </div>
                  <span className="ml-2">AI活用マーケ講座</span>
                  <FontAwesomeIcon
                    icon={openAISection ? faChevronDown : faChevronRight}
                    className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  />
                </a>
                {openAISection && (
                  <ul>
                    <li className="pl-2">
                      <a
                        className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                        href="/articles"
                      >
                        <div className="text-gray-700">
                          <ChevronRightCircle className="w-5 h-5" />
                        </div>
                        <span className="ml-2">すべての講座</span>
                      </a>
                    </li>
                    <li className="pl-2">
                      <a
                        className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                        href="/articles/article_categories/tiktok"
                      >
                        <div className="text-gray-700">
                          <ChevronRightCircle className="w-5 h-5" />
                        </div>
                        <span className="ml-2">TikTok収益化部</span>
                      </a>
                    </li>
                    <li className="pl-2">
                      <a
                        className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                        href="/articles/article_categories/youtube"
                      >
                        <div className="text-gray-700">
                          <ChevronRightCircle className="w-5 h-5" />
                        </div>
                        <span className="ml-2">YouTube収益化部</span>
                      </a>
                    </li>
                    <li className="pl-2">
                      <a
                        className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                        href="/articles/article_categories/uranai"
                      >
                        <div className="text-gray-700">
                          <ChevronRightCircle className="w-5 h-5" />
                        </div>
                        <span className="ml-2">副業占い部</span>
                      </a>
                    </li>
                    <li className="pl-2">
                      <a
                        className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                        href="/articles/article_categories/eigyou"
                      >
                        <div className="text-gray-700">
                          <ChevronRightCircle className="w-5 h-5" />
                        </div>
                        <span className="ml-2">営業入門講座</span>
                      </a>
                    </li>
                    <li className="pl-2">
                      <a
                        className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                        href="/articles/article_categories/collabo"
                      >
                        <div className="text-gray-700">
                          <ChevronRightCircle className="w-5 h-5" />
                        </div>
                        <span className="ml-2">コラボ記事</span>
                      </a>
                    </li>
                    <li className="pl-2">
                      <a
                        className="flex items-center p-2 text-md font-normal text-gray-700 rounded-lg hover:bg-gray-100 "
                        href="/articles/article_categories/katuyou"
                      >
                        <div className="text-gray-700">
                          <ChevronRightCircle className="w-5 h-5" />
                        </div>
                        <span className="ml-2">クラウドワークスAI活用</span>
                      </a>
                    </li>
                  </ul>
                )}
              </li>

              <li>
                <a
                  className="flex items-center p-2 text-md font-semibold text-gray-700 rounded-lg hover:bg-gray-100 "
                  href={
                    Cookies.get("ad_ref")
                      ? `/users/sign_up/${Cookies.get("ad_ref")}`
                      : "/users/sign_up"
                  }
                >
                  <div className="text-gray-700">
                    <UserPlus className="w-5 h-5" />
                  </div>
                  <span className="ml-2 font-bold underline decoration-orange-500 decoration-4">
                    新規登録
                  </span>
                </a>
              </li>
              <li>
                <a
                  className="flex items-center p-2 text-md font-semibold text-gray-700 rounded-lg hover:bg-gray-100 "
                  href="/users/sign_in"
                >
                  <div className="text-gray-700">
                    <LogIn className="w-5 h-5" />
                  </div>
                  <span className="ml-2">ログイン</span>
                </a>
              </li>

              <li>
                <a
                  className="flex items-center p-2 text-md font-semibold text-gray-700 rounded-lg hover:bg-gray-100"
                  target="_blank"
                  href="https://a-i-tech.co/privacy_policy"
                >
                  <div className="text-gray-700">
                    <FileText className="w-5 h-5" />
                  </div>
                  <span className="ml-2">プライバシーポリシー</span>
                </a>
              </li>

              <li>
                <a
                  className="flex items-center p-2 text-md font-semibold text-gray-700 rounded-lg hover:bg-gray-100"
                  href="/contract"
                >
                  <div className="text-gray-700">
                    <FileText className="w-5 h-5" />
                  </div>
                  <span className="ml-2">利用規約</span>
                </a>
              </li>

              <li>
                <a
                  className="flex items-center p-2 text-md font-semibold text-gray-700 rounded-lg hover:bg-gray-100"
                  href="/affiliate_guideline"
                >
                  <div className="text-gray-700">
                    <FileText className="w-5 h-5" />
                  </div>
                  <span className="ml-2">アフィリエイトガイドライン</span>
                </a>
              </li>

              <li>
                <a
                  className="flex items-center p-2 text-md font-semibold text-gray-700 rounded-lg hover:bg-gray-100 "
                  href="/tokusho"
                >
                  <div className="text-gray-700">
                    <FileText className="w-5 h-5" />
                  </div>
                  <span className="ml-2">特定商取引法</span>
                </a>
              </li>

              <li>
                <a
                  className="flex items-center p-2 text-md font-semibold text-gray-700 rounded-lg hover:bg-gray-100 "
                  href="https://a-i-tech.co/"
                  target="_blank"
                >
                  <div className="text-gray-700">
                    <FileText className="w-5 h-5" />
                  </div>
                  <span className="ml-2">会社概要</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </ul>
    </>
  );
};

export default SidebarLists;
