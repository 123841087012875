import React, { useState, useMemo } from "react";
import { createConsumer } from "@rails/actioncable";

//interfaces
import { WhisperAi } from "@/interfaces/whisper_ai";
import { User } from "@/interfaces/user";
import { InputField } from "@/interfaces/input_field";
import { BizGenerationResult } from "@/interfaces/biz/users_ai";

import PromptSettingForm from "@/components/biz/users/ais/whisper/PromptSettingForm";
import AiMessage from "@/components/biz/users/ais/chat/messages/Ai";
import Header from "@/components/biz/users/ais/whisper/Header";
import TranscribedText from "@/components/biz/users/ais/whisper/TranscribedText";
import { BizRetrievedReference } from "@/interfaces/biz/retrieved_reference";

type Props = {
  whisperAi: WhisperAi;
  inputFields: InputField[];
  user: User;
  transcribedText: string;
};

const UsersWhisperAisResult: React.FC<Props> = (props) => {
  const [transcribeText, setTranscribeText] = useState(props.transcribedText);
  const [streaming, setStreaming] = useState(false);
  const [subscription, setSubscription] = useState<any>(null);
  const [message, setMessage] = useState("");
  const [generationResult, setGenerationResult] =
    useState<BizGenerationResult>(null);
  const [retrievedReferences, setRetrievedReferences] = useState<
    Array<BizRetrievedReference>
  >([]);

  const cable = useMemo(() => createConsumer(), []);

  const generateRandomString = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    return result;
  };

  const onSubmitPromptSettingForm = (data) => {
    // 生成結果表示コンポーネントは generation result がセットされていないと何も表示されない仕様になっているため
    // いったん ID なしの仮の generation result をセットしておく
    //
    // 生成完了後にはサーバーから返ってくる generation result を改めてセットする
    setGenerationResult({
      id: null,
      userId: Number(props.user.id),
      private: false,
    });
    setStreaming(true);
    data["transcribe_text"] = transcribeText;
    // ここでランダムの文字列生成
    const token = generateRandomString(20);
    const sub = cable.subscriptions.create(
      { channel: "Biz::PrivateChatChannel", token: token },
      {
        connected: () => {
          sub.perform("stream_message", {
            ai_slug: props.whisperAi.ai.slug,
            form_data: data,
            token: token,
            private: 0,
            user_token: props.user.token,
          });
        },
        received: (res) => {
          if (res.error) {
            alert(res.error);
            setMessage("申し訳ございません、問題が発生しました");
            if (res?.errorCode == "upgrade") {
              location.reload();
            }
          } else if (res.status) {
            switch (res.status) {
              case "streaming":
                if (res?.data != "") {
                  setMessage((prev) => prev + res?.data);
                }
                break;
              case "finish":
                setStreaming(false);
                setGenerationResult(res.generationResult.data);
                break;
              case "reset":
                setMessage("");
                break;
            }
          }
        },
      }
    );
    setSubscription(sub);
    setMessage("");
  };

  const cancelStreaming = () => {
    setStreaming(false);
    if (subscription) {
      subscription.unsubscribe();
    }
  };

  return (
    <>
      <Header whisperAi={props.whisperAi} />

      {transcribeText != null && (
        <>
          <div className="p-4 mt-4 rounded-lg w-full inline-block rounded-tl-none text-gray-800 whitespace-pre-wrap break-words">
            <TranscribedText text={transcribeText} />
            <div className="mt-6">
              <PromptSettingForm
                submit={(datas) => onSubmitPromptSettingForm(datas)}
                inputFields={props.inputFields}
                streaming={streaming}
                cancelStreaming={cancelStreaming}
                user={props.user}
                ctaText={props.whisperAi.ctaText}
              />
            </div>
          </div>
        </>
      )}
      {message != "" && (
        <div className="p-4 mb-10">
          <AiMessage
            message={message}
            ai={props.whisperAi.ai}
            streaming={streaming}
            generationResult={generationResult}
            retrievedReferences={retrievedReferences}
          />
        </div>
      )}
      {streaming && (
        <>
          <div className="fixed bottom-0 left-0 w-full flex justify-center">
            <button
              type="button"
              className="inline-flex mb-5 justify-center items-center rounded-md bg-blue-500 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={cancelStreaming}
            >
              <i className="fa-solid fa-stop mr-2"></i>
              <span>生成をストップ</span>
            </button>
          </div>
        </>
      )}
    </>
  );
};

export default UsersWhisperAisResult;
