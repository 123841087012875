import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FieldValues, SubmitHandler, useForm} from "react-hook-form";
import {RootState} from "@/components/biz/pages/users/ais/store";
import {
  resetToken,
  setInitialFormValues,
  setIsLoading, setToken,
  showChat,
} from "@/components/biz/pages/users/ais/ChatSlice";
import {AiForm} from "@/interfaces/ai_form";
import {uploadFile} from "@/libs/api/biz/users/users_ai";
import {toast} from "react-toastify";

type Props = {
  aiForms: AiForm[];
};

type FormValues = Record<string, any>;

const InputForms: React.FC<Props> = ({aiForms}) => {
  const dispatch = useDispatch();
  const {register, handleSubmit, reset, formState: {errors}} = useForm<Record<string, string | FileList>>();
  const initialFormValues = useSelector<RootState, Record<string, string>>(
    (state) => state.chat.initialFormValues
  );
  const ai = useSelector((state: RootState) => state.chat.ai);
  const token = useSelector((state: RootState) => state.chat.token);
  const tokenRef = useRef<string>(token);
  useEffect(() => {
    tokenRef.current = token;
  }, [token]);
  useEffect(() => {
    if (initialFormValues) {
      reset(initialFormValues);
    }
  }, [initialFormValues, reset]);

  const onSubmit: SubmitHandler<FieldValues> = async (datas) => {
    dispatch(setIsLoading(true));
    dispatch(resetToken());
    const classifiedData: {
      textInputs: Record<string, string>;
      fileInputs: Record<string, File[]>;
    } = classifyFormData(datas);
    const {textInputs, fileInputs} = classifiedData;
    const token = createFormToken();
    dispatch(setToken(token));

    if (Object.keys(fileInputs).length > 0) {
      const promises = Object.entries(fileInputs).map(([inputName, files]) => {
        const firstFile = files[0];
        return uploadFile(ai.slug, inputName, firstFile, token)
          .then((response) => {
            if (response.data.status === "ok") {
              textInputs[response.data.inputName] = response.data.fileIdentifier;
            } else if (response.data.status === "error") {
              toast.error(`アップロードエラー: ${response.data.message}`);
            }
          })
          .catch((error) => {
            console.error(`ファイルアップロード中にエラーが発生しました (${inputName}):`, error);
            toast.error(`ファイルアップロードエラー (${inputName})`);
          });
      });
      await Promise.all(promises);
    }
    dispatch(setInitialFormValues(textInputs));
    dispatch(showChat());
    dispatch(setIsLoading(false));
  };

  const classifyFormData = (data: FormValues) => {
    const textInputs: Record<string, string> = {};
    const fileInputs: Record<string, File[]> = {};
    for (const key in data) {
      if (data[key] instanceof FileList) {
        fileInputs[key] = Array.from(data[key]);
      } else {
        textInputs[key] = data[key];
      }
    }
    return {textInputs, fileInputs};
  };
  const createFormToken = () => (crypto.randomUUID === undefined) ? generateRandomString(20) : crypto.randomUUID();

  const generateRandomString = (length) => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };
  return (
    <div className="bg-gray-100 grow">
      <div className="p-4 border-r border-gray-200">
        <form onSubmit={handleSubmit(onSubmit)}>
          {aiForms.map((input) => (
            <div className="p-4" key={input.id}>
              <label htmlFor={input.inputName} className="block text-sm font-semibold leading-6 text-gray-900">
                {input.label}
              </label>
              {input.kind === "text_area" ? (
                <textarea
                  {...register(input.inputName, {required: true})}
                  className="w-full rounded bg-white shadow-sm p-2"
                />
              ) : input.kind === "input_select" && input.options ? (
                <select
                  {...register(input.inputName, {required: true})}
                  className="w-full rounded bg-white shadow-sm p-2"
                >
                  {input.options.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.name}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  type={input.kind === "input_file" ? "file" : "text"}
                  {...register(input.inputName, {required: true})}
                  className="w-full rounded bg-white shadow-sm p-2"
                />
              )}
              <p className="mt-1 text-xs text-gray-500">{input.helpText}</p>
              {errors[input.inputName] && (
                <p className="font-semibold text-sm text-red-400">
                  {input.label}を入力してください
                </p>
              )}
            </div>
          ))}
          <div className="px-4">
            <button
              type="submit"
              className="w-full rounded bg-cwaipurple-600 py-2 mb-4 shadow-sm hover:opacity-90"
            >
              <span className="text-md font-semibold text-white">送信</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InputForms;
