import React, { useState, useEffect, useMemo } from "react";
import { createConsumer } from "@rails/actioncable";
import { Ai } from "../../../interfaces/ai";
import { AiForm } from "../../../interfaces/ai_form";
import { DifyWorkflowApp } from "../../../interfaces/dify_workflow_app";
import { OrganizationOptions } from "../../../interfaces/organization_options";
import { MainCategory } from "../../../interfaces/main_category";
import { SubCategory } from "../../../interfaces/sub_category";
import { LeafCategory } from "../../../interfaces/leaf_category";
import { toast } from "react-toastify";
import {
  useForm,
  useFieldArray,
  Controller,
  FieldValues,
} from "react-hook-form";
import Select from "react-select";

import PromptSettingForm from "../../users/ais/chat/PromptSettingForm";
import PromptEditor from "./PromptEditor";
import AiMessage from "../../users/ais/chat/messages/Ai";
import FormRow from "../../admins/ais/form/FormRow";
import { UploadedFile } from "../../users/ais/forms/InputFile";
import { AxiosResponse } from "axios";
import {
  uploadFile,
  UploadFileResponse,
} from "../../../libs/api/biz/users/users_ai";
import Loading from "../../biz/Loading";

type Props = {
  ai: Ai;
  aiForms: AiForm[];
  mainCategories: MainCategory[];
  difyWorkflowApps: DifyWorkflowApp[];
  organizations: OrganizationOptions[];
  permittedOrganizations: OrganizationOptions[];
  getSubCategories: (params: {
    mainCategorySlug: string;
  }) => Promise<{ data: SubCategory[] }>;
  getLeafCategories: (params: {
    subCategorySlug: string;
  }) => Promise<{ data: LeafCategory[] }>;
  updateAi: (slug: string, formData: FormData) => Promise<{ data: any }>;
  createAi: (formData: FormData) => Promise<{ data: any }>;
  bulkPostAiForms: (params: {
    aiForms: AiForm[];
    aiId: string;
    deletedIds: string[];
  }) => Promise<{ data: AiForm[] }>;
};

const AdminsAisEdit: React.FC<Props> = (props) => {
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [leafCategories, setLeafCategories] = useState<LeafCategory[]>([]);
  const [editImage, setEditImage] = useState(props.ai.image?.url == null);
  const [aiForms, setAiForms] = useState<AiForm[]>(props.aiForms);
  const [streaming, setStreaming] = useState(false);
  const [message, setMessage] = useState("");
  const [subscription, setSubscription] = useState<any>(null);
  const [deletedIds, setDeletedIds] = useState<string[]>([]);
  const [useDify, setUseDify] = useState<Boolean>(
    props.ai.difyWorkflowApp != null
  );
  const [nowLoading, setNowLoading] = useState(false);

  const cable = useMemo(() => createConsumer(), []);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm<Ai>({
    defaultValues: {
      name: props.ai.name,
      intro: props.ai.intro,
      modelType: props.ai.modelType,
      modelVersion: props.ai.modelVersion,
      image: {},
      mainCategorySlug: props.ai.mainCategorySlug,
      subCategorySlug: props.ai.subCategorySlug,
      leafCategorySlug: props.ai.leafCategorySlug,
      userMessage: props.ai.userMessage,
      maxTokens: props.ai.maxTokens,
      temperature: props.ai.temperature,
      assistMessage: props.ai.assistMessage,
      orderAssistMessage: props.ai.orderAssistMessage,
      prompt: props.ai.prompt,
      orderPrompt: props.ai.orderPrompt,
      lastSystemMessage: props.ai.lastSystemMessage,
      claudeUserMessage: props.ai.claudeUserMessage,
      orderIdx: props.ai.orderIdx,
      status: props.ai.status,
      slug: props.ai.slug,
      adCount: props.ai.adCount,
      paidFlag: props.ai.paidFlag,
      claudeSystem: props.ai.claudeSystem,
      toC: props.ai.toC,
      toB: props.ai.toB,
      recommended: props.ai.recommended,
      difyWorkflowAppId: props.ai.difyWorkflowAppId,
      forWhisper: props.ai.forWhisper,
      permittedOrganizations: props.permittedOrganizations,
    },
  });

  // 特定のフィールドを監視
  const modelType = watch("modelType");

  useEffect(() => {
    if (modelType && modelType !== props.ai.modelType) {
      setValue("modelVersion", "");
    }
  }, [modelType]);

  const { fields, append, remove } = useFieldArray({
    name: `lastSystemMessage`,
    control: control,
  });

  const {
    fields: claudeFields,
    append: appendClaude,
    remove: removeClaude,
  } = useFieldArray({
    name: "claudeUserMessage",
    control,
  });

  useEffect(() => {
    if (fields.length == 0) {
      append("");
    }
  }, [props.ai.lastSystemMessage]);

  useEffect(() => {
    if (claudeFields.length == 0) {
      appendClaude("");
    }
  }, [props.ai.claudeUserMessage]);

  useEffect(() => {
    if (props.ai.mainCategorySlug != null) {
      props
        .getSubCategories({
          mainCategorySlug: props.ai.mainCategorySlug,
        })
        .then(({ data }) => {
          setSubCategories(data);
        });
    }
  }, [props.ai.mainCategorySlug]);

  useEffect(() => {
    if (props.ai.subCategorySlug != null) {
      props
        .getLeafCategories({
          subCategorySlug: props.ai.subCategorySlug,
        })
        .then(({ data }) => {
          setLeafCategories(data);
        });
    }
  }, [props.ai.subCategorySlug]);

  useEffect(() => {
    if (!useDify) {
      setValue("difyWorkflowAppId", "");
    }
  }, [useDify]);

  const onSubmit = async (data) => {
    //console.log("data", data);
    const formData = new FormData();
    formData.append("ai[name]", data.name || "");
    formData.append("ai[intro]", data.intro || "");
    formData.append("ai[modelType]", data.modelType || "chatgpt");
    formData.append("ai[modelVersion]", data.modelVersion || "");
    if (data.image instanceof File) {
      formData.append("ai[image]", data.image);
    }
    formData.append(
      "ai[lastSystemMessage]",
      JSON.stringify(data.lastSystemMessage)
    );
    formData.append(
      "ai[claudeUserMessage]",
      JSON.stringify(data.claudeUserMessage)
    );
    formData.append("ai[mainCategorySlug]", data.mainCategorySlug || "");
    formData.append("ai[subCategorySlug]", data.subCategorySlug || "");
    formData.append("ai[leafCategorySlug]", data.leafCategorySlug || "");
    formData.append("ai[userMessage]", data.userMessage || "");
    formData.append("ai[maxTokens]", data.maxTokens || "");
    formData.append("ai[temperature]", data.temperature || "");
    formData.append("ai[difyWorkflowAppId]", data.difyWorkflowAppId || "");
    formData.append("ai[assistMessage]", data.assistMessage || "");
    formData.append("ai[orderAssistMessage]", data.orderAssistMessage || "");
    formData.append("ai[prompt]", data.prompt || "");
    formData.append("ai[claudeSystem]", data.claudeSystem || "");
    formData.append("ai[orderPrompt]", data.orderPrompt || "");
    formData.append("ai[orderIdx]", data.orderIdx || "");
    formData.append("ai[adCount]", data.adCount || "");
    formData.append("ai[status]", data.status || "");
    formData.append("ai[paidFlag]", data.paidFlag || false);
    formData.append("ai[toC]", data.toC || false);
    formData.append("ai[toB]", data.toB || false);
    formData.append("ai[recommended]", data.recommended || false);
    formData.append("ai[forWhisper]", data.forWhisper || false);
    formData.append(
      "ai[permittedOrganizations]",
      data.permittedOrganizations.map((org) => org.value) || []
    );

    if (data.slug != null) {
      formData.append("ai[slug]", data.slug || "");
    }
    try {
      if (props.ai?.id != null) {
        await props.updateAi(props.ai.slug, formData);
        toastSuccess("更新しました");
      } else {
        await props.createAi(formData);
        toastSuccess("作成しました");
      }
    } catch (error) {
      let errorMessage = error.response.data.errors.join(", ");
      toast.error(`更新に失敗しました。${errorMessage}`);
      console.error(error);
    }
  };
  const toastSuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleFileChange = (event) => {
    console.log("event.target.files[0]", event.target.files[0]);
    setValue("image", event.target.files[0]);
  };

  const onChangeMainCategory = async (v: string) => {
    setSubCategories([]);
    setLeafCategories([]);
    let { data } = await props.getSubCategories({
      mainCategorySlug: v,
    });
    setSubCategories(data);
  };

  const onChangeSubCategory = async (v: string) => {
    setLeafCategories([]);
    let { data } = await props.getLeafCategories({
      subCategorySlug: v,
    });
    setLeafCategories(data);
  };

  const generateRandomString = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    return result;
  };

  const subscribe = (data: FieldValues, token: string) => {
    setStreaming(true);

    const sub = cable.subscriptions.create(
      { channel: "PrivateChatChannel", token: token },
      {
        connected: () => {
          sub.perform("stream_message", {
            ai_slug: props.ai.slug,
            form_data: data,
            token: token,
          });
        },
        disconnected: () => {
          toast.error("/usersでログインしていないため生成できません");

          setStreaming(false);
        },
        received: (res) => {
          if (res.error) {
            alert(res.error);
            setMessage("申し訳ございません、問題が発生しました");
          } else if (res.status) {
            if (res.status == "finish") {
              setStreaming(false);
            }
          } else {
            if (res?.data != "") {
              setMessage((prev) => prev + res?.data);
            }
          }
        },
      }
    );
    setSubscription(sub);
  };

  const onSubmitPromptSettingForm = async (
    data: FieldValues,
    uploadedFiles: UploadedFile[]
  ) => {
    const token =
      crypto.randomUUID === undefined
        ? generateRandomString(20)
        : crypto.randomUUID();

    if (uploadedFiles.length >= 1) {
      setNowLoading(true);

      const promises: Promise<AxiosResponse<UploadFileResponse>>[] =
        uploadedFiles.map((uploadedFile) => {
          return uploadFile(
            props.ai.slug,
            uploadedFile.inputName,
            uploadedFile.file,
            token
          );
        });

      // ファイルが全てアップロード完了するのを待ってから submitForm を実行する
      Promise.all(promises).then((responses) => {
        responses.forEach((response) => {
          switch (response.data.status) {
            case "error":
              toast.error(response.data.message);
              break;
            case "ok":
              // アップロードされたファイルを判別するため FormData に inputName = fileIdentifier を追加しておく
              data[response.data.inputName] = response.data.fileIdentifier;
              subscribe(data, token);
              break;
            default:
              alert("不明なエラーが発生しました");
              break;
          }

          setNowLoading(false);
        });
      });
    } else {
      subscribe(data, token);
    }

    setMessage("");
  };

  const removeAiForm = (aiForm: AiForm, i: number) => {
    setAiForms((prevForms) => {
      return prevForms.filter((prevForm: AiForm, j: number) => {
        if (i != j) {
          return prevForm;
        }
      });
    });
    setDeletedIds([...deletedIds, aiForm.id]);
  };

  const updateAiForm = (aiForm: AiForm, i: number) => {
    setAiForms((prevForms) => {
      return prevForms.map((prevForm: AiForm, j: number) => {
        if (i == j) {
          return aiForm;
        } else {
          return prevForm;
        }
      });
    });
  };

  const moveUp = (index) => {
    console.log("moveUp", index);
    if (index === 0) return;
    const newData = [...aiForms];
    console.log("newData", newData);
    [newData[index - 1], newData[index]] = [newData[index], newData[index - 1]];
    console.log("newData", newData);
    setAiForms(newData);
  };

  const moveDown = (index) => {
    if (index === aiForms.length - 1) return;
    const newData = [...aiForms];
    [newData[index + 1], newData[index]] = [newData[index], newData[index + 1]];
    setAiForms(newData);
  };

  const newAiForm: AiForm = {
    id: "",
    kind: `input_text`,
    inputName: `name${aiForms.length}`,
    label: "",
    options: [{ name: "", value: "" }],
    helpText: "",
    col: "6",
  };

  const addAiForms = () => {
    setAiForms([...aiForms, newAiForm]);
  };

  const submit = async () => {
    let { data } = await props.bulkPostAiForms({
      aiForms: aiForms,
      aiId: props.ai.id,
      deletedIds: deletedIds,
    });
    setAiForms(data);
    setDeletedIds([]);
    toastSuccess("更新しました");
  };

  const cancelStreaming = () => {
    setStreaming(false);
    if (subscription) {
      subscription.unsubscribe();
    }
  };

  return (
    <>
      <Loading flag={nowLoading} />
      <div className="shadow sm:overflow-hidden sm:rounded-md border">
        <form className="simple_form edit_ai" onSubmit={handleSubmit(onSubmit)}>
          <div className="p-6">
            <div>
              <div className="mb-4 string required ai_name">
                <label
                  className="block string required text-sm font-medium text-gray-600"
                  htmlFor="ai_name"
                >
                  名称
                  <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                    *必須
                  </span>
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 string required"
                  type="text"
                  id="ai_name"
                  {...register("name", { required: true })}
                />
                {errors.name?.type === "required" && (
                  <p className="font-semibold text-sm text-red-500">
                    入力してください
                  </p>
                )}
              </div>
            </div>
            <div>
              {editImage ? (
                <>
                  <div className="mb-4 file required ai_image">
                    <label
                      className="text-sm font-medium text-gray-600 block file required text-sm font-medium text-gray-600"
                      htmlFor="ai_image"
                    >
                      アイコン画像
                      <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                        *必須
                      </span>
                    </label>
                    <div className="grid grid-cols-12">
                      <input
                        className="col-span-10 w-full text-gray-500 border rounded mt-1 file required"
                        type="file"
                        name="ai[image]"
                        id="ai_image"
                        onChange={handleFileChange}
                      />
                      <button
                        type="button"
                        className="relative col-span-2 lg:col-span-1 md:col-span-2 rounded px-2 py-1 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => setEditImage(false)}
                      >
                        キャンセル
                      </button>
                    </div>
                    {errors.image?.type === "required" && (
                      <p className="font-semibold text-sm text-red-500">
                        入力してください
                      </p>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <label
                    className="text-sm font-medium text-gray-600 block file required text-sm font-medium text-gray-600"
                    htmlFor="ai_image"
                  >
                    アイコン画像
                  </label>
                  <div className="flex mt-1 items-center mb-4">
                    {props.ai.image && (
                      <img
                        src={props.ai.image.url}
                        className="w-20 mr-2 flex-shrink-0 rounded-full bg-gray-300"
                      />
                    )}
                    <button
                      type="button"
                      className="relative lg:col-span-1 md:col-span-2 rounded px-2 py-1 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={() => setEditImage(true)}
                    >
                      変更
                    </button>
                  </div>
                </>
              )}
            </div>

            <div>
              <div className="relative flex items-start mb-4">
                <div className="flex h-6 items-center">
                  <input
                    id="useDify"
                    aria-describedby="offers-description"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    onChange={(e) => setUseDify(e.target.checked)}
                    checked={useDify == true}
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="useDify"
                    className="font-medium text-gray-900"
                  >
                    Difyを使う
                  </label>
                </div>
              </div>
            </div>

            <div>
              <div className="relative flex items-start mb-4">
                <div className="flex h-6 items-center">
                  <input
                    id="forWhisper"
                    aria-describedby="offers-description"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    {...register("forWhisper")}
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="forWhisper"
                    className="font-medium text-gray-900"
                  >
                    文字起こしAI用
                  </label>
                </div>
              </div>
            </div>

            {useDify == false ? (
              <>
                <div className="mb-4">
                  <label
                    htmlFor="aiOption"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                  >
                    生成モデルタイプ
                  </label>
                  <select
                    id="modelType"
                    {...register("modelType", { required: true })}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="">選択してください</option>
                    <option value="chatgpt">chatgpt</option>
                    <option value="claude">claude</option>
                    <option value="claude_rag">claude（RAG用）</option>
                  </select>
                  {errors.modelType?.type === "required" && (
                    <p className="font-semibold text-sm text-red-500">
                      入力してください
                    </p>
                  )}
                </div>

                {modelType == "chatgpt" && (
                  <>
                    <div className="mb-4">
                      <label
                        htmlFor="aiOption"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                      >
                        モデルバージョン
                      </label>
                      <select
                        id="modelVersion"
                        {...register("modelVersion", { required: true })}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="">選択してください</option>
                        <option value="gpt-4o-mini">gpt-4o-mini</option>
                        <option value="gpt-4o">gpt-4o</option>
                        <option value="gpt-4o-2024-11-20">
                          gpt-4o-2024-11-20
                        </option>
                      </select>
                      {errors.modelVersion?.type === "required" && (
                        <p className="font-semibold text-sm text-red-500">
                          入力してください
                        </p>
                      )}
                    </div>
                  </>
                )}

                {modelType == "claude" && (
                  <>
                    <div className="mb-4">
                      <label
                        htmlFor="aiOption"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                      >
                        モデルバージョン
                      </label>
                      <select
                        id="modelVersion"
                        {...register("modelVersion", { required: true })}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="">選択してください</option>
                        <option value="claude-3-opus-20240229">
                          claude-3-opus-20240229
                        </option>
                        <option value="claude-3-sonnet-20240229">
                          claude-3-sonnet-20240229
                        </option>
                        <option value="claude-3-haiku-20240307">
                          claude-3-haiku-20240307
                        </option>
                      </select>
                      {errors.modelVersion?.type === "required" && (
                        <p className="font-semibold text-sm text-red-500">
                          入力してください
                        </p>
                      )}
                    </div>
                  </>
                )}

                {modelType == "claude_rag" && (
                  <>
                    <div className="mb-4">
                      <label
                        htmlFor="aiOption"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                      >
                        モデルバージョン
                      </label>
                      <select
                        id="modelVersion"
                        {...register("modelVersion", { required: true })}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option value="">選択してください</option>
                        <option value="claude-3-sonnet-20240229">
                          claude-3-sonnet-20240229
                        </option>
                      </select>
                      {errors.modelVersion?.type === "required" && (
                        <p className="font-semibold text-sm text-red-500">
                          入力してください
                        </p>
                      )}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="mb-4">
                  <label
                    htmlFor="aiOption"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                  >
                    Dify Apps
                  </label>
                  <select
                    id="difyWorkflowAppId"
                    {...register("difyWorkflowAppId", { required: true })}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    {props.difyWorkflowApps.map((app) => (
                      <option key={app.id} value={app.id}>
                        {app.name}
                      </option>
                    ))}
                  </select>
                  {errors.difyWorkflowAppId?.type === "required" && (
                    <p className="font-semibold text-sm text-red-500">
                      入力してください
                    </p>
                  )}
                </div>
              </>
            )}

            <div>
              <div className="mb-4 text optional ai_intro">
                <label
                  className="block text optional text-sm font-medium text-gray-600"
                  htmlFor="ai_intro"
                >
                  説明文
                </label>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 text optional"
                  rows={10}
                  id="ai_intro"
                  {...register("intro")}
                ></textarea>
              </div>
            </div>
            <div>
              <div className="mb-4 select required ai_main_category">
                <label
                  className="block select required text-sm font-medium text-gray-600"
                  htmlFor="ai_main_category_id"
                >
                  大カテゴリ
                  <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ">
                    *必須
                  </span>
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select required"
                  id="ai_main_category_id"
                  {...register("mainCategorySlug", { required: true })}
                  onChange={(e) => onChangeMainCategory(e.target.value)}
                >
                  {props.mainCategories.map((c, i) => {
                    return (
                      <option key={`main-category-${i}`} value={c.slug}>
                        {c.name}
                      </option>
                    );
                  })}
                </select>
                {errors.mainCategorySlug?.type === "required" && (
                  <p className="font-semibold text-sm text-red-500">
                    入力してください
                  </p>
                )}
              </div>
            </div>
            {subCategories.length > 0 && (
              <div>
                <div className="mb-4 select optional ai_sub_category">
                  <label
                    className="block select optional text-sm font-medium text-gray-600"
                    htmlFor="ai_sub_category_id"
                  >
                    中カテゴリ
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select optional"
                    id="ai_sub_category_id"
                    {...register("subCategorySlug")}
                    onChange={(e) => onChangeSubCategory(e.target.value)}
                  >
                    {subCategories.map(
                      (c: { slug: string; name: string }, i: number) => {
                        return (
                          <option value={c.slug} key={`sub-category-${i}`}>
                            {c.name}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
            )}
            {leafCategories.length > 0 && (
              <div>
                <div className="mb-4 select optional ai_leaf_category">
                  <label
                    className="block select optional text-sm font-medium text-gray-600"
                    htmlFor="ai_leaf_category_id"
                  ></label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   select optional"
                    id="ai_leaf_category_id"
                    {...register("leafCategorySlug")}
                  >
                    {leafCategories.map(
                      (c: { slug: string; name: string }, i: number) => {
                        return (
                          <option value={c.slug} key={`leaf-category-${i}`}>
                            {c.name}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
            )}
            <div>
              <div className="mb-4 text optional ai_assist_message">
                <label
                  className="block text optional text-sm font-medium text-gray-600"
                  htmlFor="ai_assist_message"
                >
                  アシスト文
                </label>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1 text optional"
                  rows={3}
                  id="ai_assist_message"
                  placeholder="以下の情報を入れてください"
                  {...register("assistMessage")}
                ></textarea>
              </div>
              {useDify == false && (
                <PromptEditor
                  modelType={modelType}
                  inputFields={props.aiForms}
                  register={register}
                  errors={errors}
                  fields={fields}
                  append={() => append("")}
                  remove={remove}
                  claudeFields={claudeFields}
                  appendClaude={() => appendClaude("")}
                  removeClaude={removeClaude}
                />
              )}
            </div>

            <div>
              <div className="mb-4 integer optional ai_order_idx">
                <label
                  className="block integer optional text-sm font-medium text-gray-600"
                  htmlFor="ai_order_idx"
                >
                  並び順
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   numeric integer optional"
                  type="number"
                  step="1"
                  id="ai_order_idx"
                  {...register(`orderIdx`)}
                />
              </div>
            </div>
            <div>
              <div className="mb-4 integer optional ai_ad_count">
                <label
                  className="block integer optional text-sm font-medium text-gray-600"
                  htmlFor="ai_ad_count"
                >
                  動画広告数
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   numeric integer optional"
                  type="number"
                  step="1"
                  id="ai_ad_count"
                  {...register(`adCount`)}
                />
              </div>
            </div>
            <div>
              <div className="mb-4 enum optional ai_status">
                <label
                  className="block enum optional text-sm font-medium text-gray-600"
                  htmlFor="ai_status"
                >
                  公開ステータス
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   enum optional form-control"
                  id="ai_status"
                  {...register(`status`)}
                >
                  <option value="draft">下書き</option>
                  <option value="published">公開済み</option>
                </select>
              </div>
            </div>
            <div>
              <div className="mb-4 integer optional ai_max_tokens">
                <label
                  className="block integer optional text-sm font-medium text-gray-600"
                  htmlFor="ai_max_tokens"
                >
                  Max tokens
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   numeric integer optional"
                  type="number"
                  step="1"
                  id="ai_max_tokens"
                  {...register(`maxTokens`)}
                />
              </div>
            </div>
            <div>
              <div className="mb-4 decimal optional ai_temperature">
                <label
                  className="block decimal optional text-sm font-medium text-gray-600"
                  htmlFor="ai_temperature"
                >
                  Temperature
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   numeric decimal optional"
                  type="number"
                  step="any"
                  id="ai_temperature"
                  {...register(`temperature`)}
                />
              </div>
            </div>
            <div>
              <div className="relative flex items-start mb-4">
                <div className="flex h-6 items-center">
                  <input
                    id="paidFlag"
                    aria-describedby="offers-description"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    {...register(`paidFlag`)}
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="paidFlag"
                    className="font-medium text-gray-900"
                  >
                    有料限定
                  </label>
                  <p id="offers-description" className="text-gray-500">
                    Premiumプラン限定にします
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="relative flex items-start mb-4">
                <div className="flex h-6 items-center">
                  <input
                    id="toC"
                    aria-describedby="toc-description"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    {...register(`toC`)}
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="toCFlag"
                    className="font-medium text-gray-900"
                  >
                    toC
                  </label>
                  <p id="toc-description" className="text-gray-500">
                    toCで利用可能にします
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="relative flex items-start mb-4">
                <div className="flex h-6 items-center">
                  <input
                    id="toB"
                    aria-describedby="tob-description"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    {...register(`toB`)}
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="toBFlag"
                    className="font-medium text-gray-900"
                  >
                    toB
                  </label>
                  <p id="tob-description" className="text-gray-500">
                    toBで利用可能にします
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="relative flex items-start mb-4">
                <div className="flex h-6 items-center">
                  <input
                    id="recommended"
                    aria-describedby="recommended-description"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    {...register(`recommended`)}
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label
                    htmlFor="recommended"
                    className="font-medium text-gray-900"
                  >
                    おすすめ
                  </label>
                  <p id="recommended-description" className="text-gray-500">
                    toBでおすすめに表示されます
                  </p>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label className="block decimal optional text-sm font-medium text-gray-600 mb-px">
                公開する法人
              </label>
              <p className="text-sm text-gray-500 mb-1">
                toBで選択した法人のみに公開されます。選択しない場合は全ての法人に公開されます。
              </p>
              <Controller
                control={control}
                name="permittedOrganizations"
                render={({ field }) => (
                  <Select
                    {...field}
                    /** SelectBox の id */
                    instanceId="search-select-box"
                    options={props.organizations}
                    /** 検索で、該当なしの場合のメッセージ */
                    noOptionsMessage={() => "法人が見つかりません"}
                    placeholder="公開する法人を選択してください"
                    /** 検索可能・オプション */
                    isSearchable={true}
                    components={{
                      /** Defaultで表示されているセパレーターを消す */
                      IndicatorSeparator: () => null,
                    }}
                    /** 複数選択・可能 */
                    isMulti
                    minMenuHeight={150}
                    maxMenuHeight={150}
                  />
                )}
              />
            </div>

            <div>
              <div className="mb-4 string optional ai_slug">
                <label
                  className="block string optional text-sm font-medium text-gray-600"
                  htmlFor="ai_slug"
                >
                  スラグ
                </label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 mt-1   string optional"
                  type="text"
                  id="ai_slug"
                  {...register(`slug`)}
                />
              </div>
            </div>
          </div>

          <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
            <input
              type="submit"
              name="commit"
              value="保存する"
              className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
            />
          </div>
        </form>
      </div>
      {props.ai.id != null && (
        <>
          <div className="shadow sm:overflow-hidden sm:rounded-md border mt-10">
            <div className="space-y-10 bg-white px-4 py-5 sm:p-6">
              {aiForms.map((aiForm, i) => {
                return (
                  <div
                    key={`ai-form-${i}-${aiForm.kind}-${aiForm.id}`}
                    className=""
                  >
                    <FormRow
                      aiForm={aiForm}
                      updateAiForm={(aiForm) => updateAiForm(aiForm, i)}
                      removeAiForm={() => removeAiForm(aiForm, i)}
                      index={i}
                      length={aiForms.length}
                      moveUp={moveUp}
                      moveDown={moveDown}
                    />
                  </div>
                );
              })}
              <div className="flex justify-center mt-5">
                <button
                  type="button"
                  className="rounded-md bg-white py-2 px-3 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={addAiForms}
                >
                  + 追加
                </button>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
              <button
                type="submit"
                className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-md font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                onClick={submit}
              >
                保存する
              </button>
            </div>
          </div>

          <div className="py-3 mt-5">
            <h2 className="text-xl font-bold">Preview</h2>
          </div>
          <div className="shadow sm:overflow-hidden sm:roudned-md border">
            <PromptSettingForm
              submit={(datas, uploadedFiles) =>
                onSubmitPromptSettingForm(datas, uploadedFiles)
              }
              aiForms={aiForms}
              streaming={streaming}
              cancelStreaming={cancelStreaming}
              isAdmin={true}
            />
          </div>
          {message != "" && (
            <>
              <div className="py-3 mt-5">
                <h2 className="text-xl font-bold">出力結果</h2>
              </div>
              <div className="shadow sm:overflow-hidden sm:roudned-md border p-5 pb-10">
                <AiMessage
                  message={message}
                  ai={props.ai}
                  streaming={streaming}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
export default AdminsAisEdit;
