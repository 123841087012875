import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Lock } from "lucide-react";

type Props = {
  open: boolean;
  setOpen: (bool: boolean) => void;
  redirectUrl?: string;
};
import Cookies from "js-cookie";

const RegisterAlert: React.FC<Props> = (props) => {
  const cancelButtonRef = useRef(null);

  const redirectRegister = () => {
    if (props.redirectUrl) {
      window.location.href = `/redirects/set_user_return_to_after_sign_up?user_return_to=${props.redirectUrl}`;
    } else {
      window.location.href = Cookies.get("ad_ref")
        ? `/users/sign_up/${Cookies.get("ad_ref")}`
        : "/users/sign_up";
    }
  };

  const redirectSignIn = () => {
    if (props.redirectUrl) {
      window.location.href = `/redirects/set_user_return_to_after_sign_in?user_return_to=${props.redirectUrl}`;
    } else {
      window.location.href = Cookies.get("ad_ref")
        ? `/users/sign_in/${Cookies.get("ad_ref")}`
        : "/users/sign_in";
    }
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
                    <Lock
                      className="h-6 w-6 text-yellow-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      この先の機能は会員登録後にご利用いただけます
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        10秒で使える!クレジットカード登録不要
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-col">
                  <button
                    type="button"
                    className="bg-gradient-to-r from-blue-500 to-purple-600 font-bold text-white py-3 w-full rounded-full mb-4 hover:opacity-80"
                    onClick={() => redirectRegister()}
                  >
                    新規会員登録（無料）
                  </button>
                  <button
                    type="button"
                    className="bg-gradient-to-r from-blue-500 to-purple-600 font-bold text-white py-3 w-full rounded-full mb-4 hover:opacity-80"
                    onClick={() => redirectSignIn()}
                  >
                    ログイン
                  </button>
                  <button
                    type="button"
                    className="bg-gray-100 text-gray-600 font-bold py-3 w-full rounded-full hover:opacity-80"
                    onClick={() => props.setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    キャンセル
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default RegisterAlert;
