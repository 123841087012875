import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {usersTextGenerateDetail} from "@/libs/api/biz/users/users_ai";
import {
  addSpeakInputAndResponse, clearAllSpeakMessages,
  setInitialFormValues, setThread,
  showChat,
  setUserHistories,
  setOtherHistories, addSpeakReferences, setIsChatAllowed,
} from "@/components/biz/pages/users/ais/ChatSlice";
import {Ai} from "@/interfaces/ai";
import {usersOthersTextGenerateHistories, usersTextGenerateHistories} from "@/libs/api/users/users_ai";
import {RootState} from "@/components/biz/pages/users/ais/store";
import {BizUsersAiDetail, Thread} from "@/interfaces/biz/users_ai";
import {FormNameAndValue} from "@/interfaces/users_ai";

type Log = {
  id: number;
  sidebarTitle: string;
};

type LogsProps = {
  ai: Ai;
};

type Logs = {
  date: string;
  threads: Thread[];
}

const Logs: React.FC<LogsProps> = ({ai}) => {
  const [selectedTab, setSelectedTab] = useState("user");
  const [selectedHistory, setSelectedHistory] = useState<string | null>(null);
  const dispatch = useDispatch();
  const aiForms = useSelector((state: RootState) => state.chat.aiForms);
  const initialFormValues = useSelector<RootState, Record<string, string>>(
    (state) => state.chat.initialFormValues
  );
  const speakMessages = useSelector((state: RootState) => state.chat.speakMessages);
  const userHistories = useSelector((state: RootState) => state.chat.userHistories);
  const otherHistories = useSelector((state: RootState) => state.chat.otherHistories);

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
    setSelectedHistory(null);
  };

  const getTabClassName = (tab: string) => {
    return `inline-block p-4 grow ${
      selectedTab === tab
        ? "text-cwaipurple-600 border-b-2 border-cwaipurple-600"
        : "text-gray-900 hover:text-cwaipurple-600 border-b"
    }`;
  };

  const fetchHistoryDetails = async (thread: Thread) => {
    try {
      const {data} = await usersTextGenerateDetail(ai.slug, thread.id);
      dispatch(clearAllSpeakMessages());
      dispatch(setIsChatAllowed(data.enableAdditionalPrompt));
      const {id, usersAis} = data;

      dispatch(setThread({id: id}));
      usersAis.forEach((usersAi: BizUsersAiDetail, index: number) => {
        const {formToken, formNameAndValues, generatedText, retrievedReferences} = usersAi;
        let textArray = [];
        if (generatedText) {
          textArray = generatedText.split("");
        }
        if (formNameAndValues) {
          const formFieldValues: Record<string, string> = transformToFormFieldValues(formNameAndValues);
          if (index === 0) {
            dispatch(setInitialFormValues(formFieldValues));
            const summaryInput = createSummaryInput(formFieldValues);
            dispatch(addSpeakInputAndResponse({token: formToken, input: summaryInput, response: textArray}));
          } else {
            const input = formFieldValues['add_input']
            dispatch(addSpeakInputAndResponse({token: formToken, input: input, response: textArray}));
          }
        }

        if (retrievedReferences) {
          const references = retrievedReferences.map((ref) => {
            if (ref.filename && ref.downloadUrl) {
              return {filename: ref.filename, downloadUrl: ref.downloadUrl};
            }
          }).filter(Boolean);
          if (references.length > 0) {
            dispatch(addSpeakReferences({token: formToken, references}));
          }
        }
      });
      dispatch(showChat());
    } catch (error) {
      console.error("Failed to fetch history details:", error);
    }
  };

  const createSummaryInput = (formFieldValues: Record<string, string>): string => {
    return aiForms.map((form) => {
      const fieldValue = formFieldValues[form.inputName];
      return `<b>${form.label}</b><br>${fieldValue}<br>`;
    }).filter(Boolean)
      .join("");
  };

  const transformToFormFieldValues = (
    formNameAndValues: { name: string; value: string }[]
  ): Record<string, string> => {
    return formNameAndValues.reduce((acc, item) => {
      acc[item.name] = item.value; // name をキー、value を値として追加
      return acc;
    }, {} as Record<string, string>);
  };
  useEffect(() => {
  }, [speakMessages]);

  const displayedHistory = (selectedTab === "user") ? userHistories : otherHistories;

  useEffect(() => {
    usersTextGenerateHistories(ai.slug).then((res) => {
      dispatch(setUserHistories(res.data));
    });
    usersOthersTextGenerateHistories(ai.slug).then((res) => {
      dispatch(setOtherHistories(res.data));
    });
  }, [ai.slug, dispatch]);

  return (
    <div className="w-[224px] min-w-[224px] max-w-[224px] shrink-0">
      <div>
        <div className={getTabClassName("user")}>
          <button
            type="button"
            onClick={() => handleTabChange("user")}
          >
            自分の履歴
          </button>
        </div>
        <div className={getTabClassName("other")}>
          <button
            type="button"
            onClick={() => handleTabChange("other")}
          >
            他人の履歴
          </button>
        </div>
      </div>
      <div className="basis-full p-3 text-sm mb-6">
        <ul>
          {displayedHistory.map((historyGroup) => (
            <li key={historyGroup.date} className="mb-5">
              <span className="text-gray-400 text-sm rounded-full inline-block">{historyGroup.date}</span>
              <ul className="grid">
                {historyGroup.threads.map((thread) => (
                  <li key={thread.id} className="text-base cursor-pointer">
                    <button
                      type="button"
                      onClick={() => fetchHistoryDetails(thread)}
                    >
                      <span
                        className="pl-2 pt-1 line-clamp-1 text-gray-600 rounded hover:bg-gray-100">{typeof thread.sidebarTitle === "string" ? thread.sidebarTitle : "タイトル無し"}</span>
                    </button>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Logs;
