import React, {useState} from 'react';
import {Ai} from "@/interfaces/ai";
import {StarIcon as SolidStarIcon} from "@heroicons/react/24/solid";
import {StarIcon as OutlineStarIcon} from "@heroicons/react/24/outline";
import {toggleUserFavorite} from "@/libs/api/users/user_favorite_ai";
import {toast} from "react-toastify";

interface HeaderProps {
  ai: Ai;
  favorites: Ai[];
}

const Header: React.FC<HeaderProps> = ({ai, favorites}) => {
  const [isFavorite, setIsFavorite] = useState(favorites.some(favorite => favorite.id === ai.id));
  const handleFavoriteToggle = async () => {
    await toggleUserFavorite(ai.slug);
    setIsFavorite(!isFavorite);
  };
  const addFavorite = async () => {
    await toggleUserFavorite(ai.slug);
    setIsFavorite(true);
    toast.success("お気に入りに追加しました");
  }
  const removeFavorite = async () => {
    await toggleUserFavorite(ai.slug);
    setIsFavorite(false);
    toast.success("お気に入りから解除しました");
  }
  return (
    <div className="flex items-center h-full">
      <div className="flex-shrink-0">
        <div className="flex-none">
          <div className="flex items-center justify-center">
            <div className="bg-gray-100 rounded-lg text-primary w-16 h-16 flex items-center justify-center text-xl">
              <img alt="" className="object-cover w-16 h-16 flex-shrink-0 rounded-md bg-gray-300"
                   src={ai.image.thumb.url}/>
            </div>
          </div>
        </div>
      </div>
      <div className="ml-4 grow">
        <div className="flex items-center pt-1">
          <span className="text-gray-700 text-lg">{ai.name}</span>
        </div>
        <p className="pt-2 text-xs text-gray-500 line-clamp-3 min-h-[50px] w-auto">{ai.intro}</p>
      </div>
      <div>
        <button
          type="button"
          className="m-2 p-2 bg-transparent border-none "
          onClick={() =>
            isFavorite ? removeFavorite() : addFavorite()
          }
        >
          {isFavorite ? (
            <SolidStarIcon
              className="h-6 w-6 text-yellow-500"
              aria-hidden="true"
            />
          ) : (
            <OutlineStarIcon
              className="h-6 w-6 text-gray-500"
              aria-hidden="true"
            />
          )}
        </button>
      </div>
    </div>
  );
};

export default Header;
