import React, { useRef, useMemo, useEffect, useState } from "react";
import Header from "../../ais/image/Header";
import Image from "../../users/user_gen_imgs/Image";
import { ImageAi } from "../../../interfaces/image_ai";
import { ImageAiForm } from "../../../interfaces/image_ai_form";
import { UserGenImg } from "../../../interfaces/user_gen_img";
import PromptSettingForm from "../../users/ais/image/PromptSettingForm";
import UserGenImgDetailModal from "../../partials/modals/UserGenImgDetail";
import RegisterAlert from "../../../components/partials/modals/RegisterAlert";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { paginateUserGenImgs } from "../../../libs/api/user_gen_img";
import {
  useInfiniteQuery,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";

interface MetaData {
  currentPage?: number;
  nextPage?: number;
  prevPage?: number;
  totalPage?: number;
  totalCount?: number;
}
interface UserGenImgsResponse {
  userGenImgs: UserGenImg[];
  meta: MetaData;
}

type Props = {
  imageAi: ImageAi;
  imageAiForms: ImageAiForm[];
  userGenImgs: UserGenImg[];
  userGenImg?: UserGenImg;
};

const UsersAisShowWrapper: React.FC<Props> = (props) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <UsersAisShow {...props} />
    </QueryClientProvider>
  );
};

const UsersAisShow: React.FC<Props> = (props) => {
  const [streaming, setStreaming] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [modalDetailImg, setModalDetailImg] = useState<UserGenImg>();

  const [imageAiForms, setImageAiForms] = useState<ImageAiForm[]>(
    props.imageAiForms
  );

  const onSubmitPromptSettingForm = async (data: any) => {
    setModalOpen(true);
  };

  const { ref, inView } = useInView({
    threshold: 0, // 0は要素が少しでも表示されるとトリガーされる
  });

  const getUserGenImgsQuery = useInfiniteQuery<UserGenImgsResponse>(
    ["image_ais", props.imageAi.slug],
    ({ pageParam }) => {
      return paginateUserGenImgs({
        imageAiSlug: props.imageAi.slug,
        page: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage: {
        userGenImgs: UserGenImg[];
        meta: MetaData;
      }) => {
        //console.log("lastPage?.meta?", lastPage?.meta);
        if (lastPage?.meta?.nextPage == null) {
          //console.log("lastPage?.meta?", lastPage?.meta);
          return undefined;
        } else {
          //console.log("lastPage?.meta?", lastPage?.meta);
          return lastPage?.meta?.nextPage;
        }
      },
      initialData: {
        pages: [{ userGenImgs: props.userGenImgs, meta: {} }],
        pageParams: [1],
      },
    }
  );

  const openUserGenImgDetail = (img: UserGenImg) => {
    setModalDetailImg(img);
    setImgModalOpen(true);
  };

  useEffect(() => {
    if (
      inView &&
      !getUserGenImgsQuery.isFetching &&
      getUserGenImgsQuery.hasNextPage
    ) {
      getUserGenImgsQuery.fetchNextPage();
    }
  }, [inView, getUserGenImgsQuery.isFetching, getUserGenImgsQuery.hasNextPage]); // 依存配列を明示的に指定

  const currentUserGenImgs =
    getUserGenImgsQuery.data &&
    getUserGenImgsQuery.data.pages.flatMap((page) => page.userGenImgs);

  return (
    <>
      <div className="flex-1 justify-between flex flex-col pb-32">
        <Header
          ai={props.imageAi}
          onClickButton={() => {
            setModalOpen(true);
          }}
        />
        <div className="sm:grid sm:grid-cols-2 mt-6">
          <div className="col-span-12">
            <div className="shadow sm:overflow-hidden sm:roudned-md border">
              <PromptSettingForm
                submit={(datas) => onSubmitPromptSettingForm(datas)}
                imageAiForms={imageAiForms}
                streaming={streaming}
                cancelStreaming={() => false}
                remainGenerateCap={0}
                userGenImg={props.userGenImg}
              />
            </div>

            <h2 className="text-gray-700 font-bold mr-3 text-lg mt-3 mb-2">
              このAIが生成した画像一覧
            </h2>

            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 5 }}
            >
              <Masonry gutter="5px">
                {currentUserGenImgs &&
                  currentUserGenImgs.map((userGenImg, i) => {
                    return (
                      <Image
                        image={userGenImg}
                        showCredit={true}
                        onClick={() => openUserGenImgDetail(userGenImg)}
                        key={`user-gen-img-${i}`}
                      />
                    );
                  })}
                <div ref={ref}>
                  {getUserGenImgsQuery.isFetchingNextPage && (
                    <p>Loading more...</p>
                  )}
                </div>
              </Masonry>
            </ResponsiveMasonry>
          </div>

          <UserGenImgDetailModal
            open={imgModalOpen}
            setOpen={setImgModalOpen}
            img={modalDetailImg}
            imageAi={props.imageAi}
          />

          <RegisterAlert
            open={modalOpen}
            setOpen={setModalOpen}
            redirectUrl={`/users/image_ais/${props.imageAi.slug}`}
          />
        </div>
      </div>
    </>
  );
};
export default UsersAisShowWrapper;
