import React, { useState, useMemo } from "react";
//interfaces
import { WhisperAi } from "@/interfaces/whisper_ai";
import { User } from "@/interfaces/user";
// API
import { transcribeMovie } from "@/components/biz/libs/api/users/whisper";

// components
import MovieUploadForm from "@/components/biz/users/ais/whisper/MovieUploadForm";
import Header from "@/components/biz/users/ais/whisper/Header";

type Props = {
  whisperAi: WhisperAi;
  user: User;
  afterUploadPath: string;
};

const UsersWhisperAisShow: React.FC<Props> = (props) => {
  const [uploading, setUploading] = useState(false);

  const onSubmitMovieUploadForm = async (datas: any) => {
    setUploading(true);
    // FormData オブジェクトの作成
    const formData = new FormData();
    // "movieFile" というキーでファイルを追加
    formData.append("movieFile", datas["movieFile"]);
    formData.append("videoUrl", datas["videoUrl"]);
    formData.append("whisperAiId", props.whisperAi.id);

    try {
      // FormData を使用してリクエストを送信
      await transcribeMovie(formData);
      setUploading(false);
      location.href = props.afterUploadPath;
    } catch (error) {
      setUploading(false);
    }
  };

  return (
    <>
      <Header whisperAi={props.whisperAi} />
      <div className="shadow sm:overflow-hidden">
        <MovieUploadForm
          submit={(datas) => onSubmitMovieUploadForm(datas)}
          uploading={uploading}
        />
      </div>
    </>
  );
};

export default UsersWhisperAisShow;
