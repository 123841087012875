import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import ChatComponent from "@/components/biz/pages/users/ais/ChatComponent";
import InputForms from "@/components/biz/pages/users/ais/InputForms";
import {RootState} from "@/components/biz/pages/users/ais/store";
import Logs from "@/components/biz/pages/users/ais/Logs";
import Header from "@/components/biz/pages/users/ais/Header";
import {Ai} from "@/interfaces/ai";
import {AiForm} from "@/interfaces/ai_form";
import {User} from "@/interfaces/user";
import Loading from "@/components/biz/Loading";
import {setAi, setAiForms, setIsChatAllowed, setUser} from "@/components/biz/pages/users/ais/ChatSlice";

type Props = {
  ai: Ai;
  aiForms: AiForm[];
  user: User;
  favorites: Ai[];
};

const MainContents: React.FC<Props> = ({ai, aiForms, user, favorites}) => {
  const dispatch = useDispatch();
  const isChatVisible = useSelector((state: RootState) => state.chat.isChatVisible);
  const isLoading = useSelector((state: RootState) => state.chat.isLoading);

  useEffect(() => {
    dispatch(setAi(ai));
    dispatch(setAiForms(aiForms));
    dispatch(setUser(user));
    if (ai.supports_file_input) {
      dispatch(setIsChatAllowed(false));
    }
  });
  return (
    <div className="h-full">
      <div
        className="flex-1 flex flex-col h-full"
        style={{ minHeight: `calc(100vh - 56px - 84px)` }}
      >
        <Loading flag={isLoading} />
        <div className="p-4 border-b">
          <Header ai={ai} favorites={favorites}/>
        </div>
          <div className="flex h-full grow">
          {isChatVisible ? <ChatComponent/> : <InputForms aiForms={aiForms}/>}
          <Logs ai={ai}/>
        </div>
      </div>
    </div>
  );
};

export default MainContents;
