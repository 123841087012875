import React from "react";
import { adminCloneImageAi } from "@/libs/api/admins/image_ai";
import { toast } from "react-toastify";

type Props = {
  slug: string;
};

const AdminsImageAiCloneLink: React.FC<Props> = (props) => {
  const onClickClone = async (e) => {
    e.preventDefault();
    await adminCloneImageAi(props.slug);
    toast("複製しました");
  };
  return (
    <>
      <div>
        <a
          className="inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
          href="#"
          onClick={onClickClone}
        >
          複製する
        </a>
      </div>
    </>
  );
};
export default AdminsImageAiCloneLink;
