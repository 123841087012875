import React from "react";
import {Provider} from "react-redux";
import store from "@/components/biz/pages/users/ais/store";
import MainContents from "@/components/biz/pages/users/ais/MainContents";
import {Ai} from "@/interfaces/ai";
import {AiForm} from "@/interfaces/ai_form";
import {User} from "@/interfaces/user";

type Props = {
  ai: Ai;
  aiForms: AiForm[];
  user: User;
  favorites: Ai[];
};
const Show2: React.FC<Props> = ({ai, user, aiForms, favorites}) => {
  return (
    <Provider store={store}>
      <MainContents ai={ai} user={user} aiForms={aiForms} favorites={favorites} />
    </Provider>
  );
};

export default Show2;
