import React, { useState, useEffect } from "react";
import { Ai } from "../../../../interfaces/ai";
import { AiForm } from "../../../../interfaces/ai_form";
import { MainCategory } from "../../../../interfaces/main_category";
import { DifyWorkflowApp } from "../../../../interfaces/dify_workflow_app";
import { OrganizationOptions } from "@/interfaces/organization_options";
import { vendorsGetSubCategories } from "../../../../libs/api/vendors/sub_category";
import { vendorsGetLeafCategories } from "../../../../libs/api/vendors/leaf_category";
import {
  vendorCreateAi,
  vendorUpdateAi,
} from "../../../../libs/api/vendors/ai";
import { vendorBulkPostAiForms } from "../../../../libs/api/vendors/ai_form";
import CommonEditor from "../../../ais/texts/Edit";

type Props = {
  ai: Ai;
  aiForms: AiForm[];
  mainCategories: MainCategory[];
  difyWorkflowApps: DifyWorkflowApp[];
  organizations: OrganizationOptions[];
  permittedOrganizations: OrganizationOptions[];
};

const VendorsAisEdit: React.FC<Props> = (props) => {
  return (
    <>
      <CommonEditor
        ai={props.ai}
        aiForms={props.aiForms}
        mainCategories={props.mainCategories}
        difyWorkflowApps={props.difyWorkflowApps}
        organizations={props.organizations}
        permittedOrganizations={props.permittedOrganizations}
        getSubCategories={vendorsGetSubCategories}
        getLeafCategories={vendorsGetLeafCategories}
        createAi={vendorCreateAi}
        updateAi={vendorUpdateAi}
        bulkPostAiForms={vendorBulkPostAiForms}
      />
    </>
  );
};
export default VendorsAisEdit;
