import React from "react";
import Sidebar from "@/components/biz/partials/sidebar/Sidebar";

export default {
  title: "Components/Sidebar",
  component: Sidebar,
};

// サンプルデータ
const mainCategories = [
  {
    title: "カテゴリー1",
    icon: "fas fa-folder",
    url: "/category1",
    items: [
      { title: "サブカテゴリー1-1", url: "/category1/sub1", slug: "sub1" },
      { title: "サブカテゴリー1-2", url: "/category1/sub2", slug: "sub2" },
    ],
  },
  {
    title: "カテゴリー2",
    icon: "fas fa-cog",
    url: "/category2",
    items: [
      { title: "サブカテゴリー2-1", url: "/category2/sub1", slug: "sub1" },
      { title: "サブカテゴリー2-2", url: "/category2/sub2", slug: "sub2" },
    ],
  },
  {
    title: "データ管理",
    icon: "fas fa-database",
    url: "/data-management",
    items: [
      { title: "データ管理1", url: "/data-management/1", slug: "data1" },
      { title: "データ管理2", url: "/data-management/2", slug: "data2" },
    ],
  },
];

// Sidebarのストーリー
export const DefaultSidebar = () => (
  <Sidebar
    mainCategories={mainCategories}
    enableRag={true}
    currentOrganizationUserRole="admin"
  />
);

export const MemberSidebar = () => (
  <Sidebar
    mainCategories={mainCategories}
    enableRag={false}
    currentOrganizationUserRole="member"
  />
);
